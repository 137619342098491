const API_VERSION = 'v4';

module.exports = {
  API_CLIENT_ID: '2Xw1sSDpGPf7YSQBjAKjukZLlXwQxAuAUfYpf3Zk',
  APP_TITLE: 'Hype Microsite',
  API_PROTOCOL: 'https://',
  API_HOST: 'api.beatportstage.com',
  API_ENDPOINT_AUTH: `/${API_VERSION}/auth/`,
  API_ENDPOINT_AUTH_AUTHORIZE: `/${API_VERSION}/auth/o/authorize/`,
  API_ENDPOINT_AUTH_INTROSPECT: `/${API_VERSION}/auth/o/introspect/`,
  API_ENDPOINT_AUTH_REDIRECT: `/${API_VERSION}/auth/o/post-message/`,
  API_ENDPOINT_AUTH_TOKEN: `/${API_VERSION}/auth/o/token/`,
  API_ENDPOINT_AUTH_LOGOUT: `/${API_VERSION}/auth/logout/`,
  API_ENDPOINT_AUTH_REVOKE: `/${API_VERSION}/auth/o/revoke/`,
  API_ENDPOINT_BILLING_INFO: `/${API_VERSION}/my/hype-billing-info/`,
  API_ENDPOINT_LABELS: `/${API_VERSION}/catalog/labels/`,
  API_ENDPOINT_PLANS: `/${API_VERSION}/plans/`,
  API_ENDPOINT_SEARCH: `/${API_VERSION}/catalog/search/`,
  API_ENDPOINT_SUBSCRIPTIONS: `/${API_VERSION}/my/hype-subscriptions/`,
  API_ENDPOINT_USERS: `/${API_VERSION}/accounts/users/`,
  API_REGISTER_URL: `/${API_VERSION}/auth/register/`,
  // ROUTES
  ROUTE_INDEX: '/',
  ROUTE_PLANS: '/plans',
  ROUTE_ENROLLED: '/enrolled',
  ROUTE_SORRY: '/sorry',
  ROUTE_THANKS: '/thanks',
  ROUTE_CHECKOUT: '/checkout',
  ROUTE_ACCOUNT: '/account',
  // INTEGRATIONS
  SENTRY_ENABLED: false,
  RECURLY_PUBLIC_KEY: 'ewr1-4ueiBhaiVVIxSnZkuyUQm9'
};
