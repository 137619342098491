const common = require('./config.common');

const config = {
  ...common,
  API_HOST: 'api.beatport.com',
  API_CLIENT_ID: 'iFRfav4JQJdG4yQOXmjcLs3SeWm2j3Te0fM3rfIj',
  IS_PROD: true,
  RECURLY_PUBLIC_KEY: 'ewr1-Qmb1cqA14hgGgPNFIMqO7D',
  SENTRY_ENABLED: true,
};

if (typeof window !== 'undefined') {
  window.Config = config;
}

module.exports = config;
